import React from 'react';
import { useField } from 'formik';

const style = require('./RadioButton.module.scss');

type RadioButtonProps = {
  children: React.ReactNode;
  name: string;
  id: string;
  value: string;
  className?: '';
  [otherProps: string]: any;
};

const RadioButton = (props: RadioButtonProps) => {
  const { children, className, id, ...rest } = props;

  const [field] = useField({ ...props, type: 'radio' });

  const { name, onBlur, onChange, value, checked } = field;

  return (
    <div className={`${style.radioButton} ${className}`}>
      <div className={style.inputWrapper}>
        <input
          {...rest}
          name={name}
          type="radio"
          id={`${id}-radio-button`}
          aria-labelledby={`${id}-radio-label`}
          tabIndex={0}
          aria-hidden="false"
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          checked={checked}
        />
        <div className={style.svgWrapper}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="28px"
            height="28px"
            viewBox="0 0 28 28"
            xmlSpace="preserve"
          >
            <g>
              <circle
                className={style.svgRadioOuterFill}
                cx="14"
                cy="14"
                r="12.5"
              />
              <path
                className={style.svgRadioOuterStroke}
                d="M14,3c6.1,0,11,4.9,11,11s-4.9,11-11,11S3,20.1,3,14S7.9,3,14,3 M14,0C6.3,0,0,6.3,0,14s6.3,14,14,14
		s14-6.3,14-14S21.7,0,14,0L14,0z"
              />
            </g>
          </svg>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="28px"
            height="28px"
            xmlSpace="preserve"
          >
            <circle className={style.svgRadioInner} cx="14" cy="14" r="8" />
          </svg>
        </div>
      </div>
      <label
        className={style.textRadio}
        id={`${id}-radio-label`}
        htmlFor={`${id}-radio-button`}
      >
        {children}
      </label>
    </div>
  );
};

RadioButton.defaultProps = {
  className: '',
};

export default RadioButton;
