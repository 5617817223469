import { useEffect } from 'react';

/**
 * Mimic Gatsby <Link> low-priority preload behavior for relative urls
 * @param urls
 */
const usePreloadPageData = (...urls: string[]) => {
  useEffect(() => {
    for (let i = 0; i < urls.length; i += 1) {
      // @ts-ignore
      window.___loader.enqueue(urls[i]);
    }
  }, []);
};

export default usePreloadPageData;
