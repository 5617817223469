import React from 'react';

const style = require('./RadioButtonGroup.module.scss');

type RadioButtonGroupProps = {
  children: React.ReactNode;
  errorText?: string;
  [otherProps: string]: any;
};

const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  const { children, className, errorText, ...rest } = props;

  return (
    <div {...rest} className={`${style.radioButtonGroup} ${className}`}>
      {children}
      {errorText && <div className={style.errorText}>{errorText}</div>}
    </div>
  );
};

RadioButtonGroup.defaultProps = {
  errorText: '',
};

export default RadioButtonGroup;
