import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import ButtonGroup from '../components/ui/ButtonGroup';
import RadioButtonGroup from '../components/ui/RadioButtonGroup';
import RadioButton from '../components/ui/RadioButton';
import StepperLayout from '../components/layouts/StepperLayout';
import {
  getBillPresenceType,
  getHasControlledLoad,
  getTariffType,
  setHasControlledLoad,
  yesNoToBool,
} from '../utils/localStorage/localStorageFunctions';
import { YesNoType } from '../utils/localStorage/localStorageTypes';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const ControlledLoad = () => {
  const [initialFormValues] = useState(() => {
    const hasControlledLoad = getHasControlledLoad();

    return { controlledLoad: hasControlledLoad || '' };
  });

  const handleNext = (values?: typeof initialFormValues) => {
    const hasControlledLoad = values?.controlledLoad as YesNoType;
    if (hasControlledLoad) {
      setHasControlledLoad(hasControlledLoad);
    } else {
      setHasControlledLoad(null);
    }

    const billPresence = getBillPresenceType();
    if (billPresence === 'noBill') {
      navigateAndSetActiveStep(StepUrl.URL_SOLAR_INSTALLED);
      return;
    }
    if (billPresence === 'paperBill') {
      if (yesNoToBool(hasControlledLoad)) {
        navigateAndSetActiveStep(StepUrl.URL_CONTROLLED_LOAD_USAGE);
        return;
      }
      navigateAndSetActiveStep(StepUrl.URL_SOLAR_INSTALLED);
    }
  };

  const handleBack = () => {
    const billType = getBillPresenceType();

    if (billType === 'noBill') {
      navigateAndSetActiveStep(StepUrl.URL_LIFE_SAVING);
      return;
    }

    const usageType = getTariffType();

    if (usageType === 'single') {
      navigateAndSetActiveStep(StepUrl.URL_SINGLE_RATE_TARIFF);
      return;
    }
    if (usageType === 'peakOff') {
      navigateAndSetActiveStep(StepUrl.URL_PEAK_OFF_PEAK_TARIFF);
      return;
    }
    if (usageType === 'block') {
      navigateAndSetActiveStep(StepUrl.URL_BLOCK_RATES_TARIFF);
    }
  };

  usePreloadPageData(
    StepUrl.URL_SOLAR_INSTALLED,
    StepUrl.URL_CONTROLLED_LOAD_USAGE,
    StepUrl.URL_YOUR_HOUSEHOLD,
    StepUrl.URL_SINGLE_RATE_TARIFF,
    StepUrl.URL_PEAK_OFF_PEAK_TARIFF,
    StepUrl.URL_BLOCK_RATES_TARIFF
  );

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={2}
        currentStep={7}
        pageUrl={StepUrl.URL_CONTROLLED_LOAD}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
          >
            {(props) => (
              <Form>
                <h2 className="my-2">Do you have controlled load?</h2>
                <div className="sub-heading">
                  This is separately metered connection to supply a specific
                  appliance, such as an electric hot water system.
                </div>
                <RadioButtonGroup className="my-2">
                  <RadioButton value="yes" name="controlledLoad" id="rad1">
                    Yes
                  </RadioButton>
                  <RadioButton value="no" name="controlledLoad" id="rad2">
                    No
                  </RadioButton>
                </RadioButtonGroup>
                <ButtonGroup className="mt-5">
                  <Button
                    type="button"
                    variant="tertiary"
                    onClick={() => {
                      handleNext();
                    }}
                  >
                    Skip
                  </Button>
                  <Button
                    type="submit"
                    disabled={props.values.controlledLoad === ''}
                  >
                    Next
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </div>
        <div className="my-3">
          <BackButton text="Back" onClick={handleBack} />
        </div>
      </StepperLayout>
    </SEOLayout>
  );
};

export default ControlledLoad;
